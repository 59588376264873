import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from "react-lottie";

import api from "../../services/api"; 
import useAuth from '../../hooks/useAuth.js';
import toastError from "../../errors/toastError";
import BuildingLottie from "../../assets/lottie/building.json";

import './style.css';

const Loading = () => {
  //  ***************
  //  ** Variables **
  //  ***************
	const location = useLocation();
	const { userData } = location.state || {}
	const { loadingCallback } = useAuth();

  const lottieOptions = {
    animationData: BuildingLottie,
    loop: true,
    background: "transparent",
    speed: "1",
    direction: "1",
    playMode: "normal",
    autoplay: true,
  };
  


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const checkLoginStatus = async () => {
			if (!userData) {
				console.log("sem informação do usuário.")
				return
			}

      try {
        const response = await api.post("/auth/login", userData);
        if (response.status === 200 && loadingCallback) loadingCallback(response.data);
      } catch (error) {
        toastError(error.response?.data?.message);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkLoginStatus, 3000);

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, [userData, loadingCallback]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className="loadingMainContainer">
      <div className="loadingSubContainer">
        <div className="loadingSubContainerImage">
          <Lottie
            options={lottieOptions}
            className="loadingSubContainerImage1"
            isClickToPauseDisabled={true}
          />
        </div>

        <div className="loadingSubContainerText">
          <span className="loadingSubContainerText1">
            Por favor aguarde
          </span>
          <span className="loadingSubContainerText2">Estamos preparando tudo para o seu primeiro acesso</span>

          <div class="loadingContainer">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
