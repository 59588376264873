import { gerarIde } from "./gerarIde";
import { gerarEmit } from "./gerarEmit";
import { gerarDest } from "./gerarDest";
import { gerarDet } from "./gerarDet";
import { gerarIcmsTot } from "./gerarIcmsTot";
import { gerarTransp } from "./gerarTransp";
import { gerarDetPag } from "./gerarDetPag";
import { gerarInfAdic } from "./gerarInfAdic";
import { buscarEmpresa } from "./funcoes/buscarEmpresa";
import { buscarCliente } from "./funcoes/buscarCliente";
import { Configuracao } from "../../../configuracao";

export const geraJsonNota = async (jsonNota, operacao, response) => {
  
  if (operacao !== 'ADICIONAR' && operacao !== 'ALTERAR') {
    return {status: 400, message: 'Informe a operação ADICIONAR ou ALTERAR'};
  }

  let dest = undefined

  // Buscar os dados da primeira empresa  
  const empresa = await buscarEmpresa();

  const cliente = jsonNota.dest && operacao === 'ALTERAR' ? await buscarCliente(jsonNota.dest) : jsonNota.dest;

  const ide = await gerarIde(operacao, jsonNota.ide, empresa, jsonNota.dest)  

  const emit = gerarEmit(operacao, 
                         empresa, 
                         operacao === 'ALTERAR' ? jsonNota.emit.id : undefined, 
                         operacao === 'ALTERAR' ? jsonNota.emit.enderEmit.id : undefined)
  
  if (cliente) {dest = gerarDest(operacao, cliente)}  
  
  const detalhesProdutos = await gerarDet(operacao, jsonNota.id ? jsonNota.det : jsonNota.item, ide, dest)    
  const det = detalhesProdutos.det
  
  const icmsTot = gerarIcmsTot(operacao, jsonNota.icmsTot, detalhesProdutos.valorTotalImpostoAproximado)
  const transp = gerarTransp(operacao, jsonNota.transp)
  const detPag = gerarDetPag(operacao, jsonNota.id ? jsonNota.detPag : jsonNota.pagamento)  
  const infAdic = gerarInfAdic(operacao, jsonNota.infAdic, detalhesProdutos.observacaoTributos)  

  const nota = {
    empresaId: empresa.id,    
    versao: Configuracao.VERSAO_NFCE,
    ide,
    emit,
    dest,
    det,
    icmsTot,
    transp,
    detPag,
    infAdic
  }

  return {status: 200, nota};
}