import React, { useState, useContext, useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Divider,
  IconButton,
  List,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import clsx from "clsx";

import { AuthContext } from "../context/Auth/AuthContext";
import { i18n } from "../translate/i18n";
import api from "../services/api";
import BackdropLoading from "../components/BackdropLoading";
import logoIcon from '../assets/icon-dark.png';
import MainListItems from "./MainListItems";

import "./loggedInLayoutStyle.css";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  layoutRoot: { display: "flex", height: "100vh", },

  drawerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1em",
    cursor: "pointer",
  },

  applicationLogo: { 
    borderRadius: "20px", 
    margin: theme.spacing(2, 1, 2.5, 2), 
    width: "32px", 
    height: "auto" 
  },

  toggleSubContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },
  
  drawerPaper: {
    ...theme.scrollbarStyles,
    padding: "0 0 0 0",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaperClose: {
    overflowX: "hidden",
    ...theme.scrollbarStyles,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: { width: theme.spacing(9), },
  },

  paper: {
    borderRadius:"20px",
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const LoggedInLayout = ({ drawerOpen, setDrawerOpen, handleToggleMenu }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:825px)');
  const navigate = useNavigate();

  const { loading } = useContext(AuthContext);

  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [isWizard, setWizard] = useState(true);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => { if (document.body.offsetWidth > 600) { setDrawerOpen(true); } }, [setDrawerOpen]);

  useEffect(() => {
    if (isSmallScreen) { setDrawerVariant("temporary"); }
    else { setDrawerVariant("permanent"); }
  }, [drawerOpen, isSmallScreen]);

  useEffect(() => {
    (async () => {
      try {
        const { data: dataEmpresa } = await api.get("/v1/empresas");
        if (dataEmpresa.empresas.length === 0) {
          setWizard(false);
        }
      } catch (error) {
      }
    })();
  }, []);


  //  ***************
  //  ** Functions **
  //  ***************
  const handleClick = () => {
    if (!drawerOpen) handleToggleMenu()
    else navigate("/");
  };



  //  ************
  //  ** Return **
  //  ************
  if (loading) { return <BackdropLoading />; }
  
  return (
    <div className={classes.layoutRoot}>
      {/* 
        **********
        ** Menu **
        **********
      */}
      {isWizard && (<Drawer
        variant= {drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)}}
        open={drawerOpen}
        anchor={"left"}
        onClose={() => setDrawerOpen(false)}
      >     

        {/*
          ************
          ** Header **
          ************
        */}
        <div className={classes.drawerHeader}>          
          <img className={classes.applicationLogo}             
            src={logoIcon} alt="" 
            onClick={handleClick}
          />
          {drawerOpen && (
            <div className={classes.toggleSubContainer}>
              <span onClick={handleClick}>
                <b>{i18n.t("drawerMenu.title")}</b>
              </span>
              <IconButton onClick={handleToggleMenu} >
                <ChevronLeftOutlinedIcon className={classes.toggleIcon} />
              </IconButton>
            </div>
          )}
        </div>

        <Divider />

        {/* 
          ***********
          ** Items **
          ***********
        */}
        <List>
          <MainListItems closeDrawer={() => setDrawerOpen(false)} isSmallScreen={isSmallScreen} />
        </List>

        <Divider />
      </Drawer>)}
      
      {/* 
        *******************
        ** Child Content **
        *******************
      */}
      <Outlet />
    </div>
  );
};

export default LoggedInLayout;