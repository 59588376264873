import React from "react";

import { Button,
	Dialog,
	DialogActions,
	DialogContent,
} from "@mui/material";  

import "./style.css";
import { formatarCnpjCpf } from "../../utils/formatacao/cnpjCpf";

const VisualizarNFCe = ({ isSmallScreen, nota, open, onClose }) => {

  // 01=Dinheiro            02=Cheque          03=Cartão de Crédito      04=Cartão de Débito    05=Crédito Loja-PRAZO
  // 10=Vale Alimentação   11=Vale Refeição   12=Vale Presente          13=Vale Combustível    15=Boleto Bancário-PRAZO
  // 16=Depósito Bancário  17=Pagamento(PIX)  18=Transferência bancária 19=Programa de fidelidade, Cashback, Crédito Virtual
  // 90= Sem pagamento     99=Outros

	const retornarTipoPagamento = (value) => {
		switch (value)
		{
			case ("01"): { return "Dinheiro"; }
			case ("02"): { return "Cheque"; }
			case ("03"): { return "Cartão de Crédito"; }
			case ("04"): { return "Cartão de Débito"; }
			case ("05"): { return "Crédito Loja-PRAZO"; }
			case ("10"): { return "Vale Alimentação"; }
			case ("11"): { return "Vale Refeição"; }
			case ("12"): { return "Vale Presente"; }
			case ("13"): { return "Vale Combustível"; }
			case ("15"): { return "Boleto Bancário-PRAZO"; }
			case ("16"): { return "Depósito Bancário"; }
			case ("17"): { return "PIX"; }
			case ("18"): { return "Transferência bancária"; }
			case ("19"): { return "Programa de fidelidade, Cashback, Crédito Virtual"; }
			case ("90"): { return "Sem pagamento"; }		
    default : { return "Outros"; }
		}
	}

	const mascaraChave = (value) => {
		try {
			return value.slice(0,2) + " " + /* codigo da uf */
				   value.slice(2,6) + " " + /* ano-mes emissao */
				   value.slice(6,20) + " " + /* CNPJ Emitente */
				   value.slice(20,22) + " " + /* Modelo */
				   value.slice(22,25) + " " + /* Serie */
				   value.slice(25,34) + " " + /* Numero */
				   value.slice(34,35) + " " + /* forma de emissao */
				   value.slice(35,43) + " " + /* Codigo numerico */
				   value.slice(43,44); /* DV */	
		} catch (error) {
			return value;	
		}	
	}

	const retornaCssSituacao = (value) => {
		switch (value)
		{
			case ("Autorizada"): { return "situacao-autorizada"; }	
			case ("Cancelada"): { return "situacao-cancelada"; }	
			case ("Inutilizada"): { return "situacao-inutilizada"; }	
    		default : { return "situacao-pendente"; }
			}

	}

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			fullscreen={true}
		>
			<DialogContent dividers style={{backgroundColor: "white", fontSize: isSmallScreen ? "10px" : ""}}>
				<div style={{border: "solid 1px black", padding: "30px", paddingTop: "5px"}}>
					<div align={"center"}><strong>EMITENTE</strong></div>
					<table width={"100%"}><tr align={"center"}><span><strong>{nota?.emit.xFant}</strong></span></tr></table>
					<span><strong>CNPJ: </strong>{formatarCnpjCpf(nota?.emit.CNPJ)} <br/> 
					<strong>Nome: </strong>{nota?.emit.xNome}</span><br/>
					<strong>Endereço: </strong><span>{nota?.emit.enderEmit.xLgr}, {nota?.emit.enderEmit.nro}, {nota?.emit.enderEmit.xCpl}</span><br/>
					<span>{nota?.emit.enderEmit.xMun} - {nota?.emit.enderEmit.UF} {nota?.emit.enderEmit.CEP} </span><br/>
					
					{(nota?.dest?.CNPJ || nota?.dest?.CPF) && <div><hr /><div align={"center"}><strong>CLIENTE</strong></div><span><strong>CPF/CNPJ: </strong>{(nota?.dest?.CNPJ ? formatarCnpjCpf(nota?.dest?.CNPJ) : formatarCnpjCpf(nota?.dest?.CPF) )} <br/> 
					{nota?.dest?.xNome && <div> <strong>Nome: </strong>{nota?.dest?.xNome} </div>}</span></div>}
					
					
					<hr />
					<span>{mascaraChave(nota?.chave)}</span><br/>
					<div style={{display: "flex", width: "100%"}}>
						
						<span style={{width: "50%"}}><strong>Espécie:</strong> {nota?.ide.mod === "55" ? "NF-e" : "NFC-e"}</span><span> </span>
						<span style={{width: "50%"}}><strong>Situação:</strong> <span class={retornaCssSituacao(nota?.situacao)}><strong>{nota?.situacao}</strong></span></span>
					</div><br/>
					<table width={"100%"}><th align={"center"}><span>PRODUTOS</span></th></table>
					<table width={"100%"}>
					<tr align={"left"}>
						<th>#</th>
						<th>Cód.</th>
						<th>Desc.</th>
						<th>Qtd.</th>
						<th>Un.</th>
						<th>Vl. Unit.</th>
						<th>Vl. Total</th>
					</tr>
					{nota?.det.map((array, index) => (
						<tr align={"right"}>
						<td>{index+1}</td>
						<td align={"center"}>{array.prod.cProd}</td>
						<td align={"left"}>{array.prod.xProd}</td>
						<td>{array.prod.qCom}</td>
						<td>{array.prod.uCom}</td>
						<td>{parseFloat(array.prod.vUnCom).toFixed(2).replace(".", ",")}</td>
						<td>{parseFloat(array.prod.vProd).toFixed(2).replace(".", ",")}</td>
						</tr>))}	
					</table>

					<table width={"100%"}>
						<tr><th align={"left"}><span><strong>QTD. TOTAL DE ITENS</strong></span> </th><th align={"right"}><span><strong>{nota?.det.length}</strong></span></th></tr>
					</table>
					<table width={"100%"}>
						<tr><th align={"left"}><span><strong>VALOR TOTAL R$</strong></span></th><th align={"right"}> <span><strong>{parseFloat(nota?.icmsTot.vNF).toFixed(2).replace(".", ",")}</strong></span><br/></th></tr>
					</table>
					<hr /><br/>
					<span>FORMA DE PAGAMENTO</span><br/>
					<table width={"100%"}>
						{nota?.detPag.map(array => (
							<tr align={"left"}><td><span>{retornarTipoPagamento(array.tPag)}</span> </td> <td align={"right"}><span>{parseFloat(array.vPag).toFixed(2).replace(".", ",") }</span></td></tr>
							))}
					</table>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => onClose(false)}
				>
					{"Fechar"}
				</Button>
			</DialogActions>
		</Dialog>
		
	);
};

export default VisualizarNFCe;
